var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('app-content',[_c('index-hero')],1),_vm._v(" "),_c('app-content',[_c('live-stream',{attrs:{"track":_vm.mainTrack,"with-full-date":""}})],1),_vm._v(" "),_c('app-content',[_c('index-desc')],1),_vm._v(" "),_c('app-content',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
      once: true, callback: isVisible => _vm.isTracksVisible = isVisible
    }),expression:"{\n      once: true, callback: isVisible => isTracksVisible = isVisible\n    }"}],attrs:{"is-client-only":"","not-lazy":""}},[(_vm.isTracksVisible)?_c('lazy-index-tracks'):_vm._e()],1),_vm._v(" "),(_vm.$i18n.locale === 'ru')?_c('app-content',[_c('index-congrats')],1):_vm._e(),_vm._v(" "),_c('app-content',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
      once: true, callback: isVisible => _vm.isSpeakersVisible = isVisible
    }),expression:"{\n      once: true, callback: isVisible => isSpeakersVisible = isVisible\n    }"}],attrs:{"is-client-only":"","not-lazy":""}},[(_vm.isSpeakersVisible)?_c('lazy-index-speakers'):_vm._e()],1),_vm._v(" "),_c('app-content',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
      once: true, callback: isVisible => _vm.isNetworkingVisible = isVisible
    }),expression:"{\n      once: true, callback: isVisible => isNetworkingVisible = isVisible\n    }"}],attrs:{"is-client-only":"","not-lazy":""}},[_c('lazy-index-networking')],1),_vm._v(" "),_c('app-content',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
      once: true, callback: isVisible => _vm.isReviewsVisible = isVisible
    }),expression:"{\n      once: true, callback: isVisible => isReviewsVisible = isVisible\n    }"}],attrs:{"is-client-only":"","not-lazy":""}},[(_vm.isReviewsVisible)?_c('lazy-index-reviews'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }