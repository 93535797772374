// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LiYOGsfp{border:1px solid #e3e3e3;border-radius:max(.91rem,min(1.35vw,1.63rem));-moz-column-gap:max(2.81rem,min(4.17vw,5rem));column-gap:max(2.81rem,min(4.17vw,5rem));display:flex;flex-wrap:wrap;justify-content:space-between;margin-bottom:max(3.38rem,min(5vw,6rem));padding:max(1.41rem,min(2.08vw,2.5rem));row-gap:max(1.9rem,min(2.81vw,3.38rem))}.\\+YCKKKgl{width:max(37.5rem,min(39.58vw,47.5rem))}._25jB03mt{text-align:right;width:max(12.5rem,min(18.23vw,21.88rem))}._85WZmrMD{color:#656565;font-size:max(1rem,min(1.04vw,1.25rem));line-height:1.4;margin-bottom:0}.OLC5RVXS{display:block}._0Rnc9Yh8,.OLC5RVXS{font-size:max(1rem,min(1.04vw,1.25rem));line-height:1.4;margin-bottom:0;margin-top:1em}._0Rnc9Yh8{color:#656565;font-weight:700}@media(max-width:991.98px){._0Rnc9Yh8{text-align:left}}.wMhGKO1F{flex-shrink:0;-o-object-fit:contain;object-fit:contain;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "LiYOGsfp",
	"start": "+YCKKKgl",
	"end": "_25jB03mt",
	"text": "_85WZmrMD",
	"download": "OLC5RVXS",
	"author": "_0Rnc9Yh8",
	"img": "wMhGKO1F"
};
module.exports = ___CSS_LOADER_EXPORT___;
