// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AxkHvJQT{border:1px solid #e3e3e3;border-radius:max(.91rem,min(1.35vw,1.63rem));-moz-column-gap:max(2.81rem,min(4.17vw,5rem));column-gap:max(2.81rem,min(4.17vw,5rem));display:flex;flex-wrap:wrap;justify-content:space-between;margin-bottom:max(3.38rem,min(5vw,6rem));padding:max(1.41rem,min(2.08vw,2.5rem));row-gap:max(1.9rem,min(2.81vw,3.38rem))}.hkMf-mkg{flex-basis:max(30.23rem,min(44.79vw,53.75rem))}.QOiOdQt2{font-size:max(1.5rem,min(3.75vw,4.5rem));letter-spacing:.03em;line-height:1;margin-bottom:.72em;text-transform:uppercase}.DX9Ozmly{color:#656565;font-size:max(1rem,min(1.04vw,1.25rem));line-height:1.4;margin-bottom:0}._0gmObuEA{flex-shrink:0;height:max(12.5rem,min(24.84vw,29.81rem));-o-object-fit:contain;object-fit:contain;width:auto}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AxkHvJQT",
	"start": "hkMf-mkg",
	"heading": "QOiOdQt2",
	"text": "DX9Ozmly",
	"img": "_0gmObuEA"
};
module.exports = ___CSS_LOADER_EXPORT___;
