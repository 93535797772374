import { render, staticRenderFns } from "./index.vue?vue&type=template&id=fa2af084&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IndexHero: require('/app/components/index/IndexHero.vue').default,AppContent: require('/app/components/app/AppContent.vue').default,LiveStream: require('/app/components/live/stream/LiveStream.vue').default,IndexDesc: require('/app/components/index/IndexDesc.vue').default,IndexCongrats: require('/app/components/index/IndexCongrats.vue').default})
