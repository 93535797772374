// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EoD9Abh1{margin-bottom:max(3.13rem,min(4.64vw,5.56rem))}.YJ5aI4RT{align-items:center;-moz-column-gap:max(.84rem,min(1.25vw,1.5rem));column-gap:max(.84rem,min(1.25vw,1.5rem));display:flex;flex-wrap:wrap;justify-content:space-between}.MCY0dT9h{font-size:max(3rem,min(6.77vw,8.13rem));margin-bottom:0}.MCY0dT9h,._8sqQSxF1{font-weight:900;line-height:1;text-transform:uppercase}._8sqQSxF1{font-size:64.6934px;font-size:max(2rem,min(3.33vw,4rem));letter-spacing:-.03em}@media(max-width:575.98px){._8sqQSxF1 br{display:none}}.AC62M7wj{height:auto;width:100%}.BWxgdknr{margin-bottom:max(1.97rem,min(2.92vw,3.5rem));margin-top:max(-3.75rem,min(-3.13vw,0rem));position:relative}@media(max-width:575.98px){.BWxgdknr{margin-top:max(1.13rem,min(1.67vw,2rem))}}.f5bnELSx{font-size:max(.38rem,min(.78vw,.94rem));left:1.33em;line-height:1.19;position:absolute;text-transform:uppercase;top:22%}@media(max-width:575.98px){.f5bnELSx{display:none}}.J9g-0A5n{height:1.27em;margin-bottom:.85em}.xz1tvnao{-moz-column-gap:max(.56rem,min(.83vw,1rem));column-gap:max(.56rem,min(.83vw,1rem));display:flex;flex-wrap:wrap;justify-content:space-between;row-gap:max(.28rem,min(.42vw,.5rem))}._6PW9KsmG{font-size:max(1rem,min(1.67vw,2rem));font-weight:400;line-height:1.2;padding-top:.1em}.yvJZBYLE{align-items:center;display:flex;gap:max(.84rem,min(1.25vw,1.5rem))}.Itt\\+\\+heC{font-size:max(1.5rem,min(4.69vw,5.63rem));font-weight:400;line-height:1;text-transform:uppercase}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "EoD9Abh1",
	"head": "YJ5aI4RT",
	"heading": "MCY0dT9h",
	"subheading": "_8sqQSxF1",
	"img": "AC62M7wj",
	"imgWrapper": "BWxgdknr",
	"place": "f5bnELSx",
	"placeIcon": "J9g-0A5n",
	"footer": "xz1tvnao",
	"text": "_6PW9KsmG",
	"textGroup": "yvJZBYLE",
	"textLg": "Itt++heC"
};
module.exports = ___CSS_LOADER_EXPORT___;
